import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Rating, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';

const INSTALLER_REVIEWS_SUMMARY = gql`
  query installerReviewsSummary($id: Int) {
    installerReviewsSummary(id: $id) {
      installationQuality
      serviceQuality
      communicationQuality
      siteEtiquette
      healthAndSafety
      average
    }
  }
`;

const useStyles = makeStyles()(() => ({
  row: {
    borderBottom: '1px solid lightgray',
  },
  averageRating: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const InstallerRating = ({ companyId }) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { loading, error, data } = useQuery(INSTALLER_REVIEWS_SUMMARY, {
    variables: { id: companyId },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const {
    installationQuality,
    serviceQuality,
    communicationQuality,
    siteEtiquette,
    healthAndSafety,
    average,
  } = data.installerReviewsSummary;

  return (
    <div>
      <div className={classes.averageRating}>
        <Rating
          precision={0.1}
          name='average-rating'
          value={average}
          readOnly
        />
        <IconButton
          aria-label='expand rating'
          size='small'
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </div>
      {expanded && (
        <table>
          <tbody>
            <tr>
              <td className={classes.row}>Installation Quality:</td>
              <td className={classes.row}>
                <Rating
                  precision={0.1}
                  name='installation-quality'
                  value={installationQuality}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <td className={classes.row}>Service Quality:</td>
              <td className={classes.row}>
                <Rating
                  precision={0.1}
                  name='service-quality'
                  value={serviceQuality}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <td className={classes.row}>Communication Quality:</td>
              <td className={classes.row}>
                <Rating
                  precision={0.1}
                  name='communication-quality'
                  value={communicationQuality}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <td className={classes.row}>Site Etiquette:</td>
              <td className={classes.row}>
                <Rating
                  precision={0.1}
                  name='site-etiquette'
                  value={siteEtiquette}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <td className={classes.row}>Health and Safety:</td>
              <td className={classes.row}>
                <Rating
                  precision={0.1}
                  name='health-and-safety'
                  value={healthAndSafety}
                  readOnly
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default InstallerRating;
