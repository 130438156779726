import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Email as EmailIcon, Phone as PhoneIcon } from '@mui/icons-material';
import ChooseInstallerDialog from './ChooseInstallerDialog';
import { makeStyles } from 'tss-react/mui';
import InstallerReviewDialog from './InstallerReviewDialog';
import InstallerChatDialog from './InstallerChatDialog';

function ListItemLink(props) {
  return <ListItem button component='a' {...props} />;
}

const useStyles = makeStyles()((theme) => ({
  card: {
    minHeight: '249px',
  },
}));

const InstallerDetails = (props) => {
  const {
    name,
    phone,
    email,
    projectId,
    installerBiddable,
    projectInstallerBids,
    installerReview,
    complete,
  } = props;
  const { classes } = useStyles();

  if (!installerBiddable || name) {
    return (
      <Card className={classes.card}>
        <CardHeader
          title={name}
          subheader='Installer'
          action={
            installerBiddable && !complete ? (
              <InstallerChatDialog projectId={projectId} />
            ) : installerReview ? (
              <InstallerReviewDialog projectId={projectId} />
            ) : null
          }
        />
        <CardContent>
          <List component='nav' aria-label='installer details'>
            {email && (
              <ListItemLink href={`mailto:${email}`}>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText primary={email} />
              </ListItemLink>
            )}
            {phone && (
              <ListItemLink href={`tel:${phone}`}>
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText primary={phone} />
              </ListItemLink>
            )}
          </List>
        </CardContent>
      </Card>
    );
  }

  if (installerBiddable && !name) {
    return (
      <Card className={classes.card}>
        <CardHeader title='Installer' />
        <CardContent>
          <ChooseInstallerDialog
            projectId={projectId}
            projectInstallerBids={projectInstallerBids}
          />
        </CardContent>
      </Card>
    );
  }
};

export default InstallerDetails;
