import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { gql, useMutation } from '@apollo/client';
import { GET_PROJECT } from '../containers/Project';
import InstallerRating from './InstallerRating';

const ACCEPT_BID = gql`
  mutation customerAcceptBid($id: ID!, $projectId: ID!) {
    customerAcceptBid(id: $id, projectId: $projectId)
  }
`;

const useStyles = makeStyles()(() => ({
  open: {
    marginLeft: 'auto',
  },
  button: {
    marginRight: 'auto',
  },
  installerName: {
    width: '250px',
  },
}));

const ChooseInstallerDialog = (props) => {
  const { classes } = useStyles();
  const { projectId, projectInstallerBids } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [acceptBid] = useMutation(ACCEPT_BID, {
    refetchQueries: [{ query: GET_PROJECT, variables: { id: projectId } }],
  });

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const setInstaller = (id) => {
    acceptBid({ variables: { id, projectId } });
    setDialogOpen(false);
  };

  if (projectInstallerBids.length === 0) {
    return (
      <React.Fragment>
        <Button
          variant='contained'
          aria-label='select an installer'
          className={classes.open}
          onClick={handleDialogOpen}
        >
          Select an Installer
        </Button>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Select an Installer</DialogTitle>
          <DialogContent>
            <Typography>
              There are no installer bids yet. You will receive an email when
              bids are available for you to review.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color='darkGrey'>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Button
        variant='contained'
        aria-label='select an installer'
        className={classes.open}
        onClick={handleDialogOpen}
      >
        Select an Installer
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle>Select an Installer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose an installer from the list below
          </DialogContentText>
          {projectInstallerBids.map((projectInstallerBid) => (
            <TableContainer component={Paper} key={projectInstallerBid.id}>
              <Table aria-label='installer bid'>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.installerName}>
                      {projectInstallerBid.installer.name}
                    </TableCell>
                    <TableCell>
                      <InstallerRating
                        companyId={projectInstallerBid.installer.id}
                      />
                    </TableCell>
                    <TableCell>£{projectInstallerBid.bid}</TableCell>
                    <TableCell align='right'>
                      <Button
                        variant='contained'
                        aria-label='choose installer'
                        className={classes.button}
                        onClick={() => setInstaller(projectInstallerBid.id)}
                      >
                        Accept
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color='darkGrey'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ChooseInstallerDialog;
