import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { makeStyles } from 'tss-react/mui';
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  CircularProgress,
} from '@mui/material';
import { blue, green } from '@mui/material/colors';
import { Check as SeenIcon } from '@mui/icons-material';
import DateFormat from './DateFormat';

const CHAT_MESSAGES_FRAGMENT = gql`
  fragment chatMessagesDataFields on ChatMessage {
    id
    senderName
    message
    senderType {
      name
    }
    seen
    createdAt
  }
`;

const GET_MESSAGES = gql`
  query customerPmChatMessages($projectId: ID!) {
    customerPmChatMessages(projectId: $projectId) {
      ...chatMessagesDataFields
    }
  }
  ${CHAT_MESSAGES_FRAGMENT}
`;

const SEND_MESSAGE = gql`
  mutation customerSendMessageToPm($chatMessageInput: ChatMessageInput) {
    customerSendMessageToPm(chatMessageInput: $chatMessageInput)
  }
`;

const SET_MESSAGES_AS_SEEN = gql`
  mutation setMessagesAsSeen($ids: [ID!]) {
    setChatMessagesAsSeen(ids: $ids)
  }
`;

const NEW_CHAT_MESSAGE_SUBSCRIPTION = gql`
  subscription customerPmChatMessageCreated($projectId: ID!) {
    customerPmChatMessageCreated(projectId: $projectId) {
      ...chatMessagesDataFields
    }
  }
  ${CHAT_MESSAGES_FRAGMENT}
`;

const CHAT_MESSAGES_UPDATED_SUBSCRIPTION = gql`
  subscription customerPmChatMessagesUpdated($projectId: ID!) {
    customerPmChatMessagesUpdated(projectId: $projectId) {
      ...chatMessagesDataFields
    }
  }
  ${CHAT_MESSAGES_FRAGMENT}
`;

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  messageList: {
    height: 430,
    overflowY: 'scroll',
    overflowX: 'none',
    width: '100%',
    minWidth: 505,
  },
  customerMessage: {
    backgroundColor: blue[100],
    borderRadius: '10px',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    alignSelf: 'flex-start',
  },
  incomingMessage: {
    backgroundColor: green[100],
    borderRadius: '10px',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  sendButton: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  messageText: {
    wordWrap: 'break-word',
    fontSize: '14px',
  },
  span: {
    display: 'block',
  },
}));

const ProjectManagerChatDialog = (props) => {
  const { classes } = useStyles();
  const { projectId } = props;
  const [message, setMessage] = React.useState('');
  const [markedAsSeen, setMarkedAsSeen] = React.useState(false);
  const [sendMessage] = useMutation(SEND_MESSAGE);
  const [setMessagesAsSeen, { data: messagesMarkedAsSeen }] =
    useMutation(SET_MESSAGES_AS_SEEN);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const messageListRef = React.useRef(null);
  const {
    loading,
    error,
    data: chatMessagesData,
    subscribeToMore,
  } = useQuery(GET_MESSAGES, {
    variables: { projectId },
  });

  React.useEffect(() => {
    if (
      messagesMarkedAsSeen &&
      messagesMarkedAsSeen.setChatMessagesAsSeen === 'OK'
    ) {
      setMarkedAsSeen(true);
    }
  }, [messagesMarkedAsSeen]);

  React.useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: NEW_CHAT_MESSAGE_SUBSCRIPTION,
      variables: { projectId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newMessage = subscriptionData.data.customerPmChatMessageCreated;

        return Object.assign({}, prev, {
          customerPmChatMessages: [...prev.customerPmChatMessages, newMessage],
        });
      },
    });
    return () => unsubscribe();
  }, [projectId, subscribeToMore]);

  React.useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: CHAT_MESSAGES_UPDATED_SUBSCRIPTION,
      variables: { projectId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const updatedMessages = prev.customerPmChatMessages.map(
          (prevMessage) => {
            const updatedMessage =
              subscriptionData.data.customerPmChatMessagesUpdated.find(
                (message) => message.id === prevMessage.id
              );
            return updatedMessage ?? prevMessage;
          }
        );

        return Object.assign({}, prev, {
          customerPmChatMessages: updatedMessages,
        });
      },
    });
    return () => unsubscribe();
  }, [projectId, subscribeToMore]);

  React.useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTo(0, messageListRef.current.scrollHeight);
    }
  }, [chatMessagesData]);

  if (loading) return <CircularProgress color='inherit' />;
  if (error) return <p>Error :(</p>;

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSendMessage = () => {
    sendMessage({
      variables: {
        chatMessageInput: {
          projectId,
          message,
        },
      },
    });
    setMessage('');
  };

  const handleListScroll = () => {
    if (markedAsSeen) return;
    const { scrollTop, clientHeight, scrollHeight } = messageListRef.current;
    if (scrollHeight - scrollTop === clientHeight) {
      const ids = chatMessagesData.customerPmChatMessages
        .filter((m) => !m.seen && m.senderType.name !== 'customer')
        .map((m) => m.id);
      if (ids.length === 0) {
        setMarkedAsSeen(true);
        return;
      }

      setMessagesAsSeen({
        variables: { ids: ids },
      });
    }
  };

  return (
    <React.Fragment>
      <Button variant='contained' onClick={handleDialogOpen}>
        Open Chat
      </Button>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogContent>
          <Box className={classes.root}>
            <Box
              ref={messageListRef}
              className={classes.messageList}
              onScroll={handleListScroll}
            >
              <List>
                {chatMessagesData.customerPmChatMessages.map((msg, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          {msg.senderName}
                          {msg.senderType.name === 'customer' && (
                            <SeenIcon fontSize='small' color='darkGrey' />
                          )}
                          {msg.senderType.name === 'customer' && msg.seen && (
                            <SeenIcon fontSize='small' color='darkGrey' />
                          )}
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <span className={classes.span}>
                            <DateFormat
                              date={msg.createdAt}
                              iFormat={`yyyy-MM-dd'T'HH:mm:ss.SSSX`}
                              oFormat='dd/MM/yyyy HH:mm'
                            />
                          </span>
                          <span className={classes.span}>{msg.message}</span>
                        </React.Fragment>
                      }
                      className={`${
                        msg.senderType.name === 'customer'
                          ? classes.customerMessage
                          : classes.incomingMessage
                      } ${classes.messageText}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box display='flex' alignItems='center' width='100%'>
              <TextField
                fullWidth
                variant='outlined'
                label='Type a message...'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && message.trim()) {
                    handleSendMessage();
                  }
                }}
              />
              <Button
                variant='contained'
                color='primary'
                onClick={handleSendMessage}
                disabled={!message.trim()}
                className={classes.sendButton}
              >
                Send
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ProjectManagerChatDialog;
