import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import DateFormat from './DateFormat';
import TimeFormat from './TimeFormat';

const ProjectDetails = (props) => {
  const {
    onSiteDate,
    onSiteTime,
    offSiteDate,
    dwgCompletedDate,
    dwgApprovedDate,
    offSiteTime,
    surveyDate,
    value,
    euAddressLong,
    euName,
  } = props;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TableContainer component={Paper}>
              <Table aria-label='project details'>
                <TableBody>
                  <TableRow>
                    <TableCell>Value</TableCell>
                    <TableCell>
                      <NumericFormat
                        value={value}
                        displayType='text'
                        thousandSeparator
                        prefix='£'
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Customer</TableCell>
                    <TableCell>{euName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Site Address</TableCell>
                    <TableCell>{euAddressLong}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TableContainer component={Paper}>
              <Table aria-label='project details'>
                <TableBody>
                  {surveyDate && (
                    <TableRow>
                      <TableCell>Survey</TableCell>
                      <TableCell>
                        <DateFormat date={surveyDate} />
                      </TableCell>
                    </TableRow>
                  )}
                  {dwgCompletedDate && (
                    <TableRow>
                      <TableCell>Drawing completed</TableCell>
                      <TableCell>
                        <DateFormat date={dwgCompletedDate} />
                      </TableCell>
                    </TableRow>
                  )}
                  {dwgApprovedDate && (
                    <TableRow>
                      <TableCell>Drawing approved</TableCell>
                      <TableCell>
                        <DateFormat date={dwgApprovedDate} />
                      </TableCell>
                    </TableRow>
                  )}
                  {onSiteDate && (
                    <TableRow>
                      <TableCell>On Site</TableCell>
                      <TableCell>
                        <DateFormat date={onSiteDate} />{' '}
                        <TimeFormat time={onSiteTime} />
                      </TableCell>
                    </TableRow>
                  )}
                  {offSiteDate && (
                    <TableRow>
                      <TableCell>Off Site</TableCell>
                      <TableCell>
                        <DateFormat date={offSiteDate} />{' '}
                        <TimeFormat time={offSiteTime} />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProjectDetails;
