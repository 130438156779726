import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Grid, CircularProgress, Typography, Pagination } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ProjectSummary from '../components/ProjectSummary';
import ProjectSearch from '../components/ProjectSearch';

const displayedProjectCount = 12;

const PROJECT_COUNT = gql`
  {
    clientProjectCount
  }
`;

const GET_PROJECTS = gql`
    query getProjects($skip: Int!) {
        clientProjects(orderBy: projectNumber_DESC, limit: ${displayedProjectCount}, skip: $skip) {
            uuid
            projectNumber
            complete
            euAddressShort
            orderNo
        }
    }
`;

const useStyles = makeStyles()({
  root: {
    flexGrow: 1,
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const Projects = (props) => {
  const { skip } = props;
  const { loading, error, data } = useQuery(GET_PROJECTS, {
    variables: { skip },
  });

  if (loading) return <CircularProgress color='inherit' />;
  if (error) return <p>Error :(</p>;

  return (
    <React.Fragment>
      {data.clientProjects.map(
        ({ uuid, projectNumber, complete, euAddressShort, orderNo }) => (
          <Grid item xs={12} sm={6} md={3} key={projectNumber}>
            <ProjectSummary
              id={uuid}
              projectNumber={projectNumber}
              euAddressShort={euAddressShort}
              orderNo={orderNo}
              complete={complete}
            />
          </Grid>
        )
      )}
    </React.Fragment>
  );
};

const ProjectList = () => {
  const { classes } = useStyles();
  const [skip, setSkip] = React.useState(0);
  const { loading, error, data } = useQuery(PROJECT_COUNT);

  if (loading) return <CircularProgress color='inherit' />;
  if (error) return <p>Error :(</p>;
  if (data.clientProjectCount < 1)
    return (
      <Typography variant='h4'>
        Nothing to see here! Check back when your company has some projects.
      </Typography>
    );

  const pages = Math.ceil(data.clientProjectCount / displayedProjectCount);

  const handlePageChange = (_, page) => {
    setSkip((page - 1) * displayedProjectCount);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.center}>
          <ProjectSearch />
        </Grid>
        <Projects skip={skip} />
        <Grid item xs={12} className={classes.center}>
          <Pagination count={pages} onChange={handlePageChange} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectList;
