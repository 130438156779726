import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Button,
  IconButton,
  Collapse,
  Box,
  TableHead,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { gql, useMutation } from '@apollo/client';
import DocumentViewer from './DocumentViewer';

const MARK_VIEWED = gql`
  mutation markViewed($id: ID!) {
    markProjectFileViewed(id: $id) {
      id
    }
  }
`;

const groupBy = (arr, criteria) => {
  return arr.reduce((obj, item) => {
    // Check if the criteria is a function to run on the item or a property of it
    const key =
      typeof criteria === 'function' ? criteria(item) : item[criteria];

    // If the key doesn't exist yet, create it
    if (!obj.hasOwnProperty(key)) {
      obj[key] = [];
    }

    // Push the value to the object
    obj[key].push(item);

    // Return the object to the next item in the loop
    return obj;
  }, {});
};

const formatDateTime = (date) => format(parseISO(date), 'dd/MM/yyyy h:mm a');

const useRowStyles = makeStyles()({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  expand: {
    width: '30px',
    paddingRight: '0px',
  },
});

const Row = (props) => {
  const { name, files, handleViewClick } = props;
  const classes = useRowStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell className={classes.expand}>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {name} ({files.length})
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <Table size='small' aria-label='files'>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Uploaded</TableCell>
                    <TableCell>Viewed</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((file) => {
                    const viewable = file.fileName.endsWith('.pdf');
                    return (
                      <TableRow key={file.url}>
                        <TableCell component='th' scope='row'>
                          {file.fileName}
                        </TableCell>
                        <TableCell>{formatDateTime(file.createdAt)}</TableCell>
                        <TableCell>
                          {file.viewedAt
                            ? formatDateTime(file.viewedAt)
                            : 'Not viewed'}
                        </TableCell>
                        <TableCell>
                          {viewable ? (
                            <Button
                              variant='contained'
                              onClick={() => handleViewClick(file)}
                            >
                              View/Download
                            </Button>
                          ) : (
                            <Button
                              variant='contained'
                              onClick={() => handleViewClick(file, false)}
                            >
                              Download
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const Documents = (params) => {
  const { files } = params;
  const [docViewerOpen, setDocViewerOpen] = React.useState(false);
  const [docTitle, setDocTitle] = React.useState('');
  const [fileUrl, setFileUrl] = React.useState('');
  const [fileName, setFileName] = React.useState('');
  const [markViewed] = useMutation(MARK_VIEWED, {
    refetchQueries: ['getProject'],
  });

  const groupedFiles = React.useMemo(
    () => groupBy(files, (f) => f.type.description),
    [files]
  );

  const handleViewClick = (file, view = true) => {
    markViewed({ variables: { id: file.id } });
    if (view) {
      // open viewer
      setDocTitle(file.type.description);
      setFileUrl(file.url);
      setFileName(file.fileName);
      setDocViewerOpen(true);
    } else {
      // download
      axios
        .get(file.url, { responseType: 'blob' })
        .then(({ data }) => saveAs(data, file.fileName));
    }
  };

  const handleModalClose = () => {
    setDocViewerOpen(false);
  };

  return (
    <Card>
      <CardHeader title='Documents' />
      <CardContent>
        <TableContainer component={Paper}>
          <Table aria-label='project documents'>
            <TableBody>
              {Object.keys(groupedFiles).map((gName) => {
                const gFiles = groupedFiles[gName];
                return (
                  <Row
                    key={gName}
                    name={gName}
                    files={gFiles}
                    handleViewClick={handleViewClick}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <DocumentViewer
          title={docTitle}
          file={fileUrl}
          fileName={fileName}
          open={docViewerOpen}
          onClose={handleModalClose}
        />
      </CardContent>
    </Card>
  );
};

export default Documents;
