import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Snackbar,
  Alert,
  Grid,
  Rating,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { gql, useMutation } from '@apollo/client';
import { GET_PROJECT } from '../containers/Project';

const LEAVE_REVIEW = gql`
  mutation createInstallerReview($installerReviewInput: InstallerReviewInput) {
    createInstallerReview(installerReviewInput: $installerReviewInput)
  }
`;

const useStyles = makeStyles()(() => ({
  open: {
    marginLeft: 'auto',
  },
  ratingLabel: {
    width: 150,
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notes: {
    display: 'flex',
  },
}));

const InstallerReviewDialog = (props) => {
  const { classes } = useStyles();
  const { projectId } = props;
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [leaveReview, { data }] = useMutation(LEAVE_REVIEW, {
    refetchQueries: [{ query: GET_PROJECT, variables: { id: projectId } }],
  });
  const [title, setTitle] = React.useState('');
  const [installationQuality, setInstallationQuality] = React.useState(0);
  const [serviceQuality, setServiceQuality] = React.useState(0);
  const [communicationQuality, setCommunicationQuality] = React.useState(0);
  const [siteEtiquette, setSiteEtiquette] = React.useState(0);
  const [healthAndSafety, setHealthAndSafety] = React.useState(0);
  const [notes, setNotes] = React.useState('');

  React.useEffect(() => {
    if (data && data.createInstallerReview === 'OK') {
      setAlertOpen(true);
    }
  }, [data]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleInstallationQuality = (event) => {
    setInstallationQuality(Number(event.target.value));
  };

  const handleServiceQualityChange = (event) => {
    setServiceQuality(Number(event.target.value));
  };

  const handleCommunicationQualityChange = (event) => {
    setCommunicationQuality(Number(event.target.value));
  };

  const handleSiteEtiquetteChange = (event) => {
    setSiteEtiquette(Number(event.target.value));
  };

  const handleHealthAndSafetyChange = (event) => {
    setHealthAndSafety(Number(event.target.value));
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleSubmit = (id) => {
    leaveReview({
      variables: {
        installerReviewInput: {
          projectId,
          title,
          installationQuality,
          serviceQuality,
          communicationQuality,
          siteEtiquette,
          healthAndSafety,
          notes,
        },
      },
    });
    setDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant='contained'
        aria-label='rate installer'
        className={classes.open}
        onClick={handleDialogOpen}
      >
        Rate Installer
      </Button>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Rate Installer</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            id='title'
            label='Add a headline'
            fullWidth
            value={title}
            required
            onChange={handleTitleChange}
          />
          <Grid container alignItems='center' spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item className={classes.ratingLabel}>
                  <Typography>Installation</Typography>
                </Grid>
                <Grid item>
                  <Rating
                    value={installationQuality}
                    onChange={handleInstallationQuality}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item className={classes.ratingLabel}>
                  <Typography>Service</Typography>
                </Grid>
                <Grid item>
                  <Rating
                    value={serviceQuality}
                    onChange={handleServiceQualityChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item className={classes.ratingLabel}>
                  <Typography>Communication</Typography>
                </Grid>
                <Grid item>
                  <Rating
                    value={communicationQuality}
                    onChange={handleCommunicationQualityChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item className={classes.ratingLabel}>
                  <Typography>Site Etiquette</Typography>
                </Grid>
                <Grid item>
                  <Rating
                    value={siteEtiquette}
                    onChange={handleSiteEtiquetteChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item className={classes.ratingLabel}>
                  <Typography>Health and Safety</Typography>
                </Grid>
                <Grid item>
                  <Rating
                    value={healthAndSafety}
                    onChange={handleHealthAndSafetyChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <TextField
            margin='dense'
            id='notes'
            label='Add a written review'
            multiline
            rows={4}
            fullWidth
            value={notes}
            onChange={handleNotesChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color='darkGrey'>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color='primary'
            disabled={
              installationQuality === 0 ||
              serviceQuality === 0 ||
              communicationQuality === 0 ||
              siteEtiquette === 0 ||
              healthAndSafety === 0
            }
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Alert
          onClose={handleAlertClose}
          severity='success'
          elevation={6}
          variant='filled'
        >
          Thank you for your feedback!
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default InstallerReviewDialog;
