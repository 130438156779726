import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Chip,
  Button,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import ProjectProgress from './ProjectProgress';

const useStyles = makeStyles()({
  open: {
    marginLeft: 'auto',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  cardContent: {
    flexGrow: 1,
  },
  cardActions: {
    marginTop: 'auto',
  },
});

const ProjectSummary = (props) => {
  const {
    id,
    projectNumber,
    euAddressShort,
    onSiteDate,
    surveyDate,
    complete,
    orderNo,
    showProgress,
    dwgCompletedDate,
    dwgApprovedDate,
    issuedToProduction,
    installer,
    installerBiddable,
  } = props;
  const { classes } = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader
        title={`P${projectNumber} ${euAddressShort}`}
        subheader={orderNo}
        action={
          complete ? (
            <Chip
              size='small'
              label='Complete'
              color={complete ? 'default' : 'primary'}
            />
          ) : null
        }
      />
      <CardContent className={classes.cardContent}>
        {showProgress && (
          <ProjectProgress
            surveyDate={surveyDate}
            dwgCompletedDate={dwgCompletedDate}
            dwgApprovedDate={dwgApprovedDate}
            issuedToProduction={issuedToProduction}
            onSiteDate={onSiteDate}
            complete={complete}
            installer={installer}
            installerBiddable={installerBiddable}
          />
        )}
      </CardContent>
      {id && (
        <CardActions disableSpacing className={classes.cardActions}>
          <Button
            component={Link}
            to={`/projects/${id}`}
            variant='contained'
            aria-label='open'
            className={classes.open}
          >
            Open
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default ProjectSummary;
