import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Grid, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ProjectSummary from '../components/ProjectSummary';
import ProjectManagerDetails from '../components/ProjectManagerDetails';
import InstallerDetails from '../components/InstallerDetails';
import Documents from '../components/Documents';
import ProjectDetails from '../components/ProjectDetails';

export const GET_PROJECT = gql`
  query getProject($id: ID!) {
    clientProject(id: $id) {
      uuid
      projectNumber
      orderNo
      value
      complete
      euName
      euAddressShort
      euAddressLong
      onSiteDate
      onSiteTime
      plantOffHireDate
      offSiteTime
      user {
        name
        email
      }
      installer {
        name
        phoneNumber
        email
      }
      surveyDate
      drawingCompletedDate
      drawingApprovedDate
      issuedToProduction
      files {
        id
        url
        fileName
        createdAt
        viewedAt
        type {
          description
        }
      }
      installerBiddable
      projectInstallerBids {
        id
        installer {
          id
          name
        }
        bid
      }
      installerReview {
        id
      }
    }
  }
`;

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const Project = () => {
  const { classes } = useStyles();
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_PROJECT, {
    variables: { id },
  });

  if (loading) return <CircularProgress color='inherit' />;
  if (error) return <p>Error :(</p>;
  if (!data.clientProject)
    return <Typography variant='h4'>Oh no! Project not found.</Typography>;

  const {
    uuid,
    projectNumber,
    complete,
    euAddressShort,
    value,
    onSiteDate,
    onSiteTime,
    plantOffHireDate,
    offSiteTime,
    user,
    installer,
    surveyDate,
    orderNo,
    euAddressLong,
    euName,
    drawingCompletedDate,
    drawingApprovedDate,
    issuedToProduction,
    files,
    installerBiddable,
    projectInstallerBids,
    installerReview,
  } = data.clientProject;

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ProjectSummary
            projectNumber={projectNumber}
            orderNo={orderNo}
            euAddressShort={euAddressShort}
            onSiteDate={onSiteDate}
            surveyDate={surveyDate}
            complete={complete}
            dwgCompletedDate={drawingCompletedDate}
            dwgApprovedDate={drawingApprovedDate}
            issuedToProduction={issuedToProduction}
            showProgress
            installer={installer?.name}
            installerBiddable={installerBiddable}
          />
        </Grid>
        <Grid item xs={12}>
          <ProjectDetails
            onSiteDate={onSiteDate}
            onSiteTime={onSiteTime}
            offSiteDate={plantOffHireDate}
            offSiteTime={offSiteTime}
            surveyDate={surveyDate}
            value={value}
            euAddressLong={euAddressLong}
            euName={euName}
            dwgCompletedDate={drawingCompletedDate}
            dwgApprovedDate={drawingApprovedDate}
          />
        </Grid>
        <Grid item xs={12}></Grid>
        {user && (
          <Grid item xs={12} md={6}>
            <ProjectManagerDetails
              name={user.name}
              email={user.email}
              projectId={uuid}
            />
          </Grid>
        )}
        {(installer || installerBiddable) && (
          <Grid item xs={12} md={6}>
            <InstallerDetails
              name={installer?.name}
              phone={installer?.phoneNumber}
              email={installer?.email}
              projectId={uuid}
              installerBiddable={installerBiddable}
              projectInstallerBids={projectInstallerBids}
              installerReview={!installerReview && complete}
              complete={complete}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Documents files={files} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Project;
