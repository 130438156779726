import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import { parseISO, isPast } from 'date-fns';

const ProjectProgress = (props) => {
  const {
    surveyDate,
    dwgCompletedDate,
    dwgApprovedDate,
    issuedToProduction,
    onSiteDate,
    complete,
    installer,
    installerBiddable,
  } = props;
  const steps = [
    'Survey',
    'Drawing',
    'Awaiting approval',
    'Approved',
    'Production',
    'On Site',
  ];

  if (installerBiddable) {
    steps.splice(5, 0, 'Installer Approval');
  }

  let step = 0;

  if (complete) {
    // mark all steps complete
    step = steps.length;
  } else {
    // work out which step the project is at
    if (surveyDate && isPast(parseISO(surveyDate))) {
      step++;
    }
    if (dwgCompletedDate && isPast(parseISO(dwgCompletedDate))) {
      step++;
    }
    if (dwgApprovedDate && isPast(parseISO(dwgApprovedDate))) {
      step++;
    }
    if (issuedToProduction) {
      step++;
    }
    if (installerBiddable && installer) {
      step++;
    }
    if (onSiteDate && isPast(parseISO(onSiteDate))) {
      step++;
    }
  }

  return (
    <Stepper activeStep={step}>
      {steps.map((label, index) => {
        return (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default ProjectProgress;
