import React from 'react';
import { parse, format } from 'date-fns';

const DateFormat = ({
  date,
  iFormat = 'yyyy-MM-dd',
  oFormat = 'dd/MM/yyyy',
}) => {
  const formattedDate = format(parse(date, iFormat, new Date()), oFormat);
  return <React.Fragment>{formattedDate}</React.Fragment>;
};

export default DateFormat;
